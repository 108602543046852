import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FeatureService } from '../../services/feature.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute, Params, Data, NavigationEnd } from '@angular/router';
import { ParentPaymentService } from 'src/app/pricing/services/parent-payment.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': '',
  })
};

@Injectable({
  providedIn: 'root'
})
export class CareerService {
  collapseNavbar = new Subject();
  private apiUrl: string;
  private userSub;
  activePlan = ''
  activeRole = ''
  private previousUrl: string;
  private currentUrl: string;
  constructor(private http: HttpClient, private _parentPaymentService: ParentPaymentService, private _authService: AuthService, private _featureService: FeatureService, private spinner: NgxSpinnerService, private _router: Router) {
    const BASE_API_URL = environment.ApiConfig.careerinfo;
    this.apiUrl = `${BASE_API_URL}`;
    this.userSub = sessionStorage.getItem('userSub')
    this._authService.getCurrentUserObj().then(data => {
      this.userSub = data.attributes.sub;
    }).catch(error => { })

    this.currentUrl = this._router.url;
    _router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });

  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  //GET Career cluster
  getCareerClusters() {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/clusters?sub=${userSub}`);
  }

  // modified the API to load data 10 by 10 on 10022023 new method
  getCareerClustersNew(skip: number, limit: number) {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/clusters?sub=${userSub}&skip=${skip}&limit=${limit}`);
  }

    //search Cluster
    searchCluster(str) {
      let userSub = sessionStorage.getItem('userSub')
      return this.http.get(`${this.apiUrl}/student/career/clusters?sub=${userSub}&q=${str}`);
    }

  //search Careers
  searchCareer(id, str) {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/careers?sub=${userSub}&cluster_id=${id}&q=${str}`);
  }
  searchTop3Career(clusterName, str) {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/clusters/careers?sub=${userSub}&cluster=${clusterName}&q=${str}`);
  }

  //GET Careers
  getStudentCareerCluster(id) {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/careers?sub=${userSub}&cluster_id=${id}`);
  }

  //GET Careers
  getCareerFit(type) {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/fit?sub=${userSub}&type=${type}`);
  }

  getRecommendedCareers() {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/recommendations?sub=${userSub}`);
  }

  getAllRecommendedCareers() {
    let userSub = sessionStorage.getItem('userSub')
    return this.http.get(`${this.apiUrl}/student/career/clusters/careers?sub=${userSub}`);
  }

  getClusterDetails(clusterid) {
    return this.http.get(`${this.apiUrl}/student/career/clusters/info?cluster_id=${clusterid}`);
  }

  getCareerDetails(careerId) {
    return this.http.get(`${this.apiUrl}/student/career/careers/info?career_id=${careerId}`);
  }


  //PATCH (eduClass is class parameter 8-10th, 10th-12th, Grade-PG)
  updateCareerClusters(eduClass) {
    return this.http.patch(`${this.apiUrl}/`, eduClass);
  }

  //POST (eduClass is class parameter 8-10th, 10th-12th, Grade-PG)
  addCareerClusters(eduClass) {
    return this.http.post(`${this.apiUrl}/`, eduClass);
  }

  //DELETE (eduClass is class parameter 8-10th, 10th-12th, Grade-PG)
  deleteCareerClusters(eduClass) {
    return this.http.delete(`${this.apiUrl}/`, eduClass);
  }

  searchCareerCluster(str) {
    return this.http.get(`${this.apiUrl}/student/dashboard/search?q=${str}`);
  }

  getUserActivePlan(role = 'Student') {
    if (role.toLowerCase() == 'parent') {
      this._parentPaymentService.getUserPlan().subscribe((planInfo: any) => {
        if (!planInfo.error && planInfo.length > 0) {
          planInfo = planInfo[0]
          planInfo.plan = this.titleCase(planInfo.plan)
          this.activePlan = planInfo.plan;
          console.log("plan", this.activePlan)
          this.spinner.hide()
        }
        else {
          this.activePlan = 'Basic'
        }
        this.spinner.hide()
      },
        error => { this.spinner.hide() })
    }
    else {
      this.spinner.show()
      this._featureService.getUserPlan().subscribe((planInfo: any) => {
        if (!planInfo.error && planInfo.length > 0) {
          planInfo = planInfo[0]
          planInfo.plan = this.titleCase(planInfo.plan)
          this.activePlan = planInfo.plan;
          console.log("plan", this.activePlan)
          this.spinner.hide()
        }
        else {
          this.activePlan = 'Basic'
        }
        this.spinner.hide()
      },
        error => { this.spinner.hide() })
    }

  }

  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
  getScore(uuid, careerId, clusterId) {
    return this.http.get(`${this.apiUrl}/student/career/quiz/result?uuid=${uuid}&cluster_id=${clusterId}&career_id=${careerId}`)
  }

  // add career fit reload function 09012023
  careerFitReload() {
    let userSub = sessionStorage.getItem('userSub')
    console.log("line no. 177", userSub);
    return this.http.get(`${this.apiUrl}/student/career/fit/reload?sub=${userSub}`);
  }


}

import { AuthService } from 'src/app/services/auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Content-Type': 'application/json',
    'Authorization': '',
  })
};

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };
  private apiUrl:string;  
  private userSub;

  constructor(private http:HttpClient, private _authService:AuthService) { 
    const BASE_API_URL = environment.ApiConfig.student;
    this.apiUrl = `${BASE_API_URL}`;
    this.userSub = sessionStorage.getItem('userSub')
    this._authService.getUserDetails().then(data => {
      this.httpOptions = {
        headers: new HttpHeaders({ 
          'Content-Type': 'application/json',
          'Authorization': data.user.signInUserSession.idToken.jwtToken,
        })        
      };
      this.userSub = data.user.attributes.sub;
      //this.http.options(this.httpOptions);
      
      
    })
  }

  //GET
  getFeatureDetails() {
    let entity_id = environment.entity_id
    if(sessionStorage.getItem('userSub'))
      this.userSub = sessionStorage.getItem('userSub');
    return this.http.get(`${this.apiUrl}/student/features/${this.userSub}?entity_id=${entity_id}`,httpOptions);    
  }

  getUserPlan() {
    if(sessionStorage.getItem('userSub'))
      this.userSub = sessionStorage.getItem('userSub');
    return this.http.get(`${this.apiUrl}/student/subscribed-plan/${this.userSub}`,httpOptions); 
  }

  getAllPlans() {
    let entity_id = environment.entity_id
    console.log('entity_id', entity_id)
    return this.http.get(`${this.apiUrl}/student/subscription-plans?entity_id=${entity_id}`,httpOptions); 
  }

}

import { CountryselectService } from './../../../shared/services/countryselect.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './../../../services/auth.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})

export class MainLayoutComponent implements OnInit, OnDestroy{
  id: number;
  public slides = [];
  public firstScreen: boolean = true;
  public SignInActive: boolean = true;
	public SignUpActive: boolean = false;
	public CreatePasswordActive: boolean = false;
  public isSmallDevice: boolean;
  public isLargeDevice: boolean;
  constructor(private _authService: AuthService,
              private router: Router,
              private _countrySelect:CountryselectService,
              public breakpointObserver: BreakpointObserver) { }
  title = 'angularowlslider';
  customOptions: any = {
    loop: true,
    stagePadding:-7,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    dotsEach : true,
    dotData:true,
    // navigation: false,
    // navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    pagination:true
    // nav: false
  }

  ngOnInit(): void {

    //Responsive UI
    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isLargeDevice = true;
        this.isSmallDevice = false;
      }
    });

    if (this.isSmallDevice){
      console.log("Translate for small devices")
      let googleTranslate = document.getElementById('google_translate_element')
      let langTooltip = document.getElementById('lang-tooltip')
      googleTranslate.style.top = '100px'
      googleTranslate.style.left = '33%'
      langTooltip.style.top = '70px'
    } else{
      let googleTranslate = document.getElementById('google_translate_element')
      let langTooltip = document.getElementById('lang-tooltip')
      googleTranslate.style.top = '20px'
      langTooltip.style.top = '17px'
    }
  }

  navigateToHome(){
    if(!this._authService.loggedIn){
      this.router.navigate(['login']);
    }
    else
    this.router.navigate(['dashboard'])
  }

  ngOnDestroy(): void {
    if (this.isSmallDevice){
      let googleTranslate = document.getElementById('google_translate_element')
      googleTranslate.style.top = '20px'
      googleTranslate.style.right = '30%'
      googleTranslate.style.left = '40%'
    }
  }
}

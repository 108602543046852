import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OtherService {
  // added authorization header on 10012023
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };
  myDate = new Date()
  date: string;
  private apiUrl:string;
  private BASEURL = ''
  constructor(private http:HttpClient, private _authService: AuthService) {
    const BASE_API_URL = environment.ApiConfig.others;
    this.apiUrl = `${BASE_API_URL}/settings/other`;
    this.BASEURL = BASE_API_URL;
    // get token from signin user session on 10012023
    this._authService.getUserDetails().then(data => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': data.user.signInUserSession.idToken.jwtToken,
        })

      };
      //this.http.options(this.httpOptions);


    })
  }

  getSurveyList() {
    // send header object on 10012023
    return this.http.get(`${this.apiUrl}/how-to-know`, this.httpOptions);  
  }

  getCurrentDate(){
    return moment().format("YYYY-MM-DD HH:mm:ss");

  }
  getHealthIssuesList() {
    return this.http.get(`${this.apiUrl}/health-concerns`);  
  }

  getReasonsToJoinList() {
    // send header object on 10012023
    return this.http.get(`${this.apiUrl}/reason-to-join`, this.httpOptions);  
  }

  getLearningDisabilityList() {
    return this.http.get(`${this.apiUrl}/disabilities`);  
  }

  // post api

  addDisabilities(userAttributes) {
    userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();  
    return this.http.post(`${this.apiUrl}/disabilities`, userAttributes);    
  }

  addHealthConcerns(userAttributes) {
    userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();  
    return this.http.post(`${this.apiUrl}/health-concerns`, userAttributes);    
  }
  addknowProteen(userAttributes) {
    userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();  
    return this.http.post(`${this.apiUrl}/how-to-know`, userAttributes);    
  }

  addReasonsJoin(userAttributes) {
    userAttributes.created_at =this.getCurrentDate();
  userAttributes.updated_at =this.getCurrentDate();  
    return this.http.post(`${this.apiUrl}/reason-to-join`, userAttributes);    
  }
  // patch api

  updateHealthConcerns(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();  
    return this.http.patch(`${this.apiUrl}/health-concerns/${uuid}`,updatedData);
  }

  updateDisabilities(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();  
    return this.http.patch(`${this.apiUrl}/disabilities/${uuid}`,updatedData);
  } 

  updateReasonsJoin(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();  
    return this.http.patch(`${this.apiUrl}/reason-to-join/${uuid}`,updatedData);
  } 

  updateknowProteen(uuid,updatedData) {
    updatedData.updated_at =this.getCurrentDate();  
    return this.http.patch(`${this.apiUrl}/how-to-know/${uuid}`,updatedData);
  }

  // delete api
  deleteDisabilities(uuid) {
    return this.http.delete(`${this.apiUrl}/disabilities/${uuid}`);
  }

  deleteReasonsJoin(uuid) {
    return this.http.delete(`${this.apiUrl}/reason-to-join/${uuid}`);
  }

  deleteHealthConcerns(uuid) {
    return this.http.delete(`${this.apiUrl}/health-concerns/${uuid}`);
  }

  deleteknowProteen(uuid) {
    return this.http.delete(`${this.apiUrl}/how-to-know/${uuid}`);
  }

  insertIntoDb(username) {
    let postData = {username:username, domain:environment.domain}
    return this.http.post(`${this.BASEURL}/signup/social`,postData);
  }

  // send eamil also with signUp/verfiy API 17102022 start

  // checkUserStatus(username) {
  //   let postData = {username:username}
  //   return this.http.post(`${this.BASEURL}/signup/verify`,postData);
  // }

  checkUserStatus(data: { username: any; email: any; }) {
    return this.http.post(`${this.BASEURL}/signup/verify`,data);
  }
  // send eamil also with signUp/verfiy API 17102022 end

  sendEmail(recipients,subject,title,name,entity_id) {
    let postData = {
      recipients:[...recipients],
      subject:subject,
      //html:html,
      title:title,
      entity_id:entity_id,
      user: {
        name:name
      },
    }
    // send header object on 10012023
    return this.http.post(`${this.BASEURL}/email`,postData, this.httpOptions);
  }

  getEntityInfo(name) {
    return this.http.get(`${this.BASEURL}/entity?name=${name}`);
  }
}
